import {
    createAsyncThunk
} from "@reduxjs/toolkit";
import { headers, headerConfiguration } from "../../store/auth/headerConfig";
import api from "../../store/api/api";

export const post_comment = createAsyncThunk(
    "comments_slice/post_comment",
    async (payload, {getState, dispatch}) => {
        const headerConfig = headerConfiguration(getState, headers)
        try {
            const url = `${process.env.REACT_APP_SERVER_URL}/policy_cards/${payload.eid}/comments/`
            const body = {
                post: payload.eid,
                author: payload.user_id,
                text: payload.comment,
                parent: payload.parent_comment_id
            }
            const response = await api(url, "POST", headerConfig, body, dispatch);
            return response

        } catch (error) {
      // console.error(error)
      return (error?.message);
        }
    }
)


export const fetch_all_comments = createAsyncThunk(
    "comments_slice/fetch_all_comments",
    async (payload, {getState, dispatch}) => {

        const headerConfig = headerConfiguration(getState, headers)

        try {
            const url = `${process.env.REACT_APP_SERVER_URL}/policy_cards/${payload.eid}/comments/`

            const response = await api(url, "GET", headerConfig, null, dispatch);
            return response

        } catch (error) {
      // console.error(error)
      return (error?.message);
        }
    }
)

// FETCH COMMENTS BY ID
export const fetch_comments_by_user = createAsyncThunk(
    "comments_slice/fetch_comments_by_user",
    async (payload, {getState, dispatch}) => {

        const headerConfig = headerConfiguration(getState, headers)

        try {
            const url = `${process.env.REACT_APP_SERVER_URL}/policy_cards/comments/${payload.user_id}/`
            const response = await api(url, "GET", headerConfig, null, dispatch);
            return response

        } catch (error) {
      // console.error(error)
      return (error?.message);
        }
    }
)

// DELETE COMMENTS API
export const delete_comment_by_id = createAsyncThunk(
    "comments_slice/delete_comment_by_id",
    async (payload, {getState, dispatch}) => {

        const headerConfig = headerConfiguration(getState, headers)

        try {
            const url = `${process.env.REACT_APP_SERVER_URL}/policy_cards/comments/${payload.eid}/delete-patch/`
            const response = await api(url, "DELETE", headerConfig, null, dispatch);
           
            if (!response) {
                return payload.eid
            } else {
                // Handle error cases here, you can throw an error or return an error object
                const errorData = await response.json();
                throw new Error(errorData.message); // For example, assuming the API returns an error message in the response
            }

        } catch (error) {
      // console.error(error)
      return (error?.message);
        }
    }
)


// UPDATE COMMENTS API
export const update_comment_by_id = createAsyncThunk(
    "comments_slice/update_comment_by_id",
    async ({eid, edited_comment}, {getState, dispatch}) => {

        const headerConfig = headerConfiguration(getState, headers)
        try {
            const url = `${process.env.REACT_APP_SERVER_URL}/policy_cards/comments/${eid}/delete-patch/`
            const body = { text: edited_comment}
            const response = await api(url, "PATCH", headerConfig, body, dispatch);
            return response

        } catch (error) {
      // console.error(error)
      return (error?.message);
        }
    }
)


// UPVOTE OR DOWNVOTE
export const reaction_activity = createAsyncThunk(
    'comments_slice/reaction_activity',
    async ({eid,user_id,content_type,vote_type}, {getState, dispatch}) => {
        
        const headerConfig = headerConfiguration(getState, headers)
        try{
            const url = `${process.env.REACT_APP_SERVER_URL}/policy_cards/${user_id}/reaction/${eid}/`
            const body = {
                voter: user_id,
                content_type,
                vote_type,
                object_id:eid,
            }
            const response = await api(url, "POST", headerConfig, body, dispatch)
            return response;
        }catch(error){
        // console.error(error)
        return (error?.message);
        }
    }
)


// UPVOTE OR DOWNVOTE
export const get_reaction_activity_by_user = createAsyncThunk(
    'comments_slice/get_reaction_activity_by_user',
    async ({eid,user_id}, {getState, dispatch}) => {
        const headerConfig = headerConfiguration(getState, headers)
        try{
            const url = `${process.env.REACT_APP_SERVER_URL}/policy_cards/${user_id}/reaction/${eid}/`
            const response = await api(url, "GET", headerConfig, dispatch)
            return response;
        }catch(error){
         // console.error(error)
        return (error?.message);
        }
    }
)
