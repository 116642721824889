export const makeApiRequest = async (url, method, headers, body) => {
    let response;
    try {
        if (body) {
            response = await fetch(url, {
                method,
                headers,
                body: JSON.stringify(body)
            })
        } else {
            response = await fetch(url, {
                method,
                headers,
            });
        }

    
        if (response.ok || response.status === 204 || response.status === 200) {
            return await response.json();
        }else if (response.status === 401) {
            return {"message": "UnAuthorized Token"}
        }else {
            const errorData = await response.json();
            return errorData
        }
    } catch (error) {
        // console.error("API request error:", error);
        // throw error;
    }
};
