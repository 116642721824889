import React from 'react'
import EachDebateActivity from './EachDebateActivity'
import { debates } from '../../../mockups/Dashboard/debates'
import { readableDate } from '../../common/set_date'

function DashTrendingDebates() {

  return (
    <div className={`bg-nightModeDark relative rounded-2xl text-white mb-10 py-5 px-4 sm:px-5`}>

        <p className='absolute -top-3 -right-5 rotate-45 text-5xl'>🔥</p>
        <p className='text-xl sm:text-2xl font-bold mb-5'>Debate Room (Trending)</p>

        {debates.slice(0,2).map(({eid,post_title, author, username, text, date_created,
                         upvote_count, downvote_count}, idx) => {
            
            const dateObject = readableDate(date_created)

            return (<div className='' key={idx}>
                <EachDebateActivity
                topic={post_title} 
                author={author}
                username={username} 
                date={dateObject}
                text={text}
                upvote_count={upvote_count}
                downvote_count={downvote_count}
         />
            </div>)
        })}
        
      
    </div>
  )
}

export default DashTrendingDebates
