import {
  createAsyncThunk
} from "@reduxjs/toolkit";
import { headers, headerConfiguration } from "../../store/auth/headerConfig";
import api from "../../store/api/api";



// CREATE TRANSCRIPT ID THUNK
export const createTranscriptID = createAsyncThunk(
  "chatbot/createTranscriptID",
  async (payload, {getState, dispatch}) => {
    
    const headerConfig = headerConfiguration(getState, headers)
    try {
      const url = `${process.env.REACT_APP_SERVER_URL}/api/transcription/`;
      const body = {
        user: payload.user
      };
      const response = await api(url, "POST", headerConfig, body, dispatch);
      return response
    } catch (error) {
      // console.error(error)
      return (error?.message);
    }
  }
);


// GET ALL TRANSCRIPTS FROM THE SERVER
export const getUserTranscriptFromAPIAsync = createAsyncThunk(
  "chatbot/getUserTranscriptFromAPIAsync",
  async (payload, {getState, dispatch}) => {

    const headerConfig = headerConfiguration(getState, headers)

    try {
      const url = `${process.env.REACT_APP_SERVER_URL}/api/all-transcripts/${payload.user_id}/`;

      const response = await api(url, "GET", headerConfig, null, dispatch);
      return response
 
    } catch (error) {
      // console.error(error)
      return (error?.message);
    }
  }
);

// SEND USER NEEDS TO SERVER THUNK
export const postUserNeed = createAsyncThunk(
  "chatbot/postUserNeed",
  async (payload, {getState, dispatch}) => {

    const headerConfig = headerConfiguration(getState, headers)

    try {
      const url = `${process.env.REACT_APP_SERVER_URL}/api/create-messages/${payload.transcriptID}/`;
      const body = {
        user_response: payload.prompt,
        user: payload.user_id
      };
      const response = await api(url, "POST", headerConfig, body, dispatch);
      return response
      
    } catch (error) {
      // console.error(error)
      return (error?.message);
    }
  }
);

// CREATE CHAT TITLE THUNK
export const createChatTitle = createAsyncThunk(
  "chatbot/createChatTitle",
  async (payload, {getState, dispatch}) => {

    const headerConfig = headerConfiguration(getState, headers)

    try {
      const url = `${process.env.REACT_APP_SERVER_URL}/api/generate-update-title/${payload.transcriptID}/`;
      const body = { title: payload.prompt };
      const response = await api(url, "POST", headerConfig, body, dispatch);
      return response
    } catch (error) {
          // console.error(error)
          return (error?.message);
    }
  }
);

// GET USER CHAT FROM API ASYNC
export const getUserChatFromAPIAsync = createAsyncThunk(
  "chatbot/getUserChatFromAPIAsync",
  async (payload, {getState, dispatch}) => {

    const headerConfig = headerConfiguration(getState, headers)

    try {
      const url = `${process.env.REACT_APP_SERVER_URL}/api/create-messages/${payload.transcript_id}/`;
      const response = await api(url, "GET", headerConfig, null, dispatch);
      return response

    } catch (error) {
      // console.error(error)
      return (error?.message);
    }
  }
);

// GET ALL MESSAGES
export const getAllMessages = createAsyncThunk(
  "chatbot/getAllMessages",
  async (_, {getState, dispatch}) => {

    const headerConfig = headerConfiguration(getState, headers)

    try {
      const url = `${process.env.REACT_APP_SERVER_URL}/api/all-messages/`;
      const response = await api(url, "GET", headerConfig, null, dispatch);
      return response
    } catch (error) {
      // console.error(error)
      return (error?.message);
    }
  }
);

// UPDATE COMMENTS API
export const updateChatTitle = createAsyncThunk(
  "comments_slice/updateChatTitle",
  async (payload, {getState,dispatch}) => {

    const headerConfig = headerConfiguration(getState, headers)

    try {
      const url = `${process.env.REACT_APP_SERVER_URL}/api/generate-update-title/${payload.transcriptID}/`;
      const body = {
        title: payload.user_response
      }
      const response = await api(url, "PATCH", headerConfig, body, dispatch);

      if(response?.message){
        const id = payload.transcriptID
        const title = payload.user_response
      return {id, title}
      } else {
        // Handle error cases here, you can throw an error or return an error object
        const errorData = await response.json();
        throw new Error(errorData.message); 
      }

    } catch (error) {
      // console.error(error)
      return (error?.message);
    }
  }
)

// AN ASYNC THUNK THAT DELETES CHATS
export const deleteChatsFromAPI = createAsyncThunk(
  "chatbot/deleteChatsFromAPI",
  async (payload, {getState, dispatch}) => {

    const headerConfig = headerConfiguration(getState, headers)

    try {
      const url = `${process.env.REACT_APP_SERVER_URL}/api/create-messages/${payload.transcriptID}/`;
      const response = await api(url, "DELETE", headerConfig, null, dispatch);

      if(!response){
        return payload.transcriptID;
      }else {
          // Handle error cases here, you can throw an error or return an error object
          const errorData = await response.json();
          throw new Error(errorData.message); // For example, assuming the API returns an error message in the response
        }
       

   } catch (error) {
      // console.error(error)
      return (error?.message);
    }
  })

  // GENERATE A SURVEY FROM THE SERVER
export const generateSurvey = createAsyncThunk(
  "chatbot/generateSurvey",
  async ({ user_id, transcript_id}, {getState, dispatch}) => {

    const headerConfig = headerConfiguration(getState, headers)

    try {
      const url = `${process.env.REACT_APP_SERVER_URL}/api/surveys/${user_id}/${transcript_id}`;
      const response = await api(url, "GET", headerConfig, null, dispatch);
      return response
    } catch (error) {
      // console.error(error)
      return (error?.message);
    }
  }
);

// UPDATE COMMENTS API
export const update_survey_by_id = createAsyncThunk(
  "chatbot/update_survey_by_id",
  async ({
    survey_id,
    ranking
  }, {getState}) => {

    const headerConfig = headerConfiguration(getState, headers)

    try {
      const url = `${process.env.REACT_APP_SERVER_URL}/api/surveys/${survey_id}/update`
      const response = await fetch(url, {
        method: "PATCH",
        headers: headerConfig,
        body: JSON.stringify({
          options: ranking
        })
      })

      if (response.ok || response.status === 204) {
        const updated_comments = await response.json()
        return updated_comments
      } else {
        // Handle error cases here, you can throw an error or return an error object
        const errorData = await response.json();
        throw new Error("This is a bad request", errorData); // For example, assuming the API returns an error message in the response
      }
    } catch (error) {
      // console.error(error)
      return (error?.message);
    }
  }
)
