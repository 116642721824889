import { Navigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { getToken } from "../store/auth/selectors";
import { useEffect } from "react";

const AuthenticatedRoute = ({ children }) => {

  const token = useSelector(getToken);

  useEffect(() => {

  }, [token])

  if (token && token?.newUser === "true") {
    return <Navigate to="/dashboard" />;
  } else if (token && token?.newUser === "false") {
    return <Navigate to="/dashboard" />;
  }else{
    return children;
  }

 

  
};

export default AuthenticatedRoute;
